import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d6cea92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-12 q-pl-md q-pt-md" }
const _hoisted_2 = { class: "text-h6 text-uppercase" }
const _hoisted_3 = { class: "curso-nome" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "text-red"
}
const _hoisted_6 = {
  key: 2,
  style: {"font-size":"18px","font-weight":"bold"}
}
const _hoisted_7 = { class: "redacao-status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_page = _resolveComponent("q-page")!
  const _component_q_spinner_facebook = _resolveComponent("q-spinner-facebook")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!

  return (_ctx.redacoes)
    ? (_openBlock(), _createBlock(_component_q_page, { key: 0 }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.redacoes, (redacaoTipo, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: redacaoTipo.id,
              class: "row"
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(index), 1)
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(redacaoTipo, (redacao) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "col-xs-12 col-sm-12 col-md-4 q-pa-md",
                  key: redacao.id
                }, [
                  _createVNode(_component_q_card, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_card_section, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_3, _toDisplayString(redacao.titulo), 1),
                          _createElementVNode("div", null, "Disciplina: " + _toDisplayString(redacao.disciplina.descricao), 1),
                          _createElementVNode("div", null, "Tipo: " + _toDisplayString(redacao.tipo_redacao.descricao), 1),
                          _createElementVNode("div", null, " Data de lançamento: " + _toDisplayString(new Date(redacao.data_inicio).toLocaleDateString("pt-BR")), 1),
                          (new Date().getTime() <= new Date(redacao.data_fim).getTime() + 86400000)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_4, " Enviar redação até o dia: " + _toDisplayString(new Date(redacao.data_fim).toLocaleDateString("pt-BR")), 1))
                            : (_openBlock(), _createElementBlock("strong", _hoisted_5, " Prazo para o envio expirou ")),
                          (redacao?.redacao_respondida?.professor)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, " Nota: " + _toDisplayString(redacao.parametros_corrigidos.reduce((a, b) => +a + +b.nota, 0)) + "/" + _toDisplayString(redacao.parametros_corrigidos.reduce((a, b) => +a + +b.peso, 0)), 1))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_7, [
                            (!redacao.redacao_respondida && true)
                              ? (_openBlock(), _createBlock(_component_q_chip, {
                                  key: 0,
                                  square: "",
                                  color: "primary",
                                  "text-color": "white",
                                  icon: "folder_open"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Disponível ")
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            (
                  redacao.redacao_respondida &&
                  redacao.redacao_respondida.status == 'A'
                )
                              ? (_openBlock(), _createBlock(_component_q_chip, {
                                  key: 1,
                                  square: "",
                                  color: "teal",
                                  "text-color": "white",
                                  icon: "attachment"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Anexo enviado ")
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            (
                  redacao.redacao_respondida &&
                  redacao.redacao_respondida.status == 'S'
                )
                              ? (_openBlock(), _createBlock(_component_q_chip, {
                                  key: 2,
                                  square: "",
                                  color: "teal",
                                  "text-color": "white",
                                  icon: "source"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Respondida ")
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            (
                  redacao.redacao_respondida &&
                  redacao.redacao_respondida.status == 'C'
                )
                              ? (_openBlock(), _createBlock(_component_q_chip, {
                                  key: 3,
                                  square: "",
                                  color: "green",
                                  "text-color": "white",
                                  icon: "check"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Corrigida ")
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            (!redacao.redacao_respondida && false)
                              ? (_openBlock(), _createBlock(_component_q_chip, {
                                  key: 4,
                                  square: "",
                                  color: "red",
                                  "text-color": "white",
                                  icon: "close"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Não Disponível ")
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            (
                  redacao.redacao_respondida &&
                  redacao.redacao_respondida.status == 'R'
                )
                              ? (_openBlock(), _createBlock(_component_q_chip, {
                                  key: 5,
                                  square: "",
                                  color: "warning",
                                  "text-color": "white",
                                  icon: "autorenew"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Solicitação de Refazer ")
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_q_separator),
                      _createVNode(_component_q_card_actions, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_btn, {
                            flat: "",
                            color: "dark-grey",
                            icon: "call_missed_outgoing",
                            label: "Ir para Redacao",
                            style: {"width":"100%"},
                            to: '/minhas-redacoes/' + redacao.id
                          }, null, 8, ["to"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]))
              }), 128))
            ]))
          }), 128))
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_q_inner_loading, {
        key: 1,
        showing: _ctx.loading
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_spinner_facebook, {
            size: "30px",
            color: "primary",
            class: "q-ma-sm"
          }),
          _createTextVNode(" Carregando... ")
        ]),
        _: 1
      }, 8, ["showing"]))
}